.Testimonails {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t {
    display: flex;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}

.left-t>:nth-child(1) {
    color: var(--orange);
    font-weight: bold;
}

.left-t>:nth-child(2),
.left-t>:nth-child(3) {
    font-size: 3rem;
    font-weight: bold;
}

.left-t>:nth-child(4) {
    text-transform: none;
    align-items: left;
    letter-spacing: 1px;
    gap: 2rem;
    line-height: 20px;
}

.right-t {
    flex: 1;
    position: relative;
}

.right-t>img {
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 3rem;
    top: -18rem;
}

.right-t>:nth-child(1) {
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 4rem;
    top: -19rem;
}

.right-t>:nth-child(2) {
    position: absolute;
    width: 17rem;
    height: 19rem;
    background: var(--planCard);
    right: 2rem;
    top: -16rem;
}

.arrows {
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: -3rem;
    left: 55rem;
}

.arrows>img {
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .Testimonails {
        flex-direction: column;
    }
    .left-t>:nth-child(2),
    .left-t>:nth-child(3) {
        font-size: xx-large;
    }
    .right-t {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-t>div {
        position: relative;
        display: none;
    }
    .right-t>img {
        top: 0;
        right: 0;
        position: relative;
    }
    .right-t>:last-child {
        display: block;
        bottom: 0;
        left: 0;
    }
}